import { useState, useEffect, useRef } from 'react'
import { StyledHeader, StyledContainer, StyledToggle, StyledMobileNav } from '@/components/styles'
import { Nav, SvgIcon, CallbackModal, CatcherModal, CityChange, MobileNav, AuthModal, CreepingLine } from '@/components'
//<CreepingLine isVisible={isVisible} />
import Link from 'next/link'
import { useCity } from '@/contexts/CityContext'
import { useInfo } from '@/contexts/InfoContext'
import { useAuth } from '@/contexts/AuthContext'
import api from '@/lib/api'
import getWindowWidth from '@/lib/getWindowWidth'
import Router from 'next/router'

export default function ({isVisible, headerStyle }) {
    const { city } = useCity()
    const { user } = useAuth()
    const windowWidth = getWindowWidth()
    const { mayBeVisible, testIsVisible } = useInfo()

    const dropdownRef = useRef()
    const contactRef = useRef()

    const [menuIsOpen, setMenuIsOpen] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [catcherModalIsOpen, setCatcherModalIsOpen] = useState(false)
    const [authModalIsOpen, setAuthModalIsOpen] = useState(false)
    const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
    const [contactIsOpen, setContactIsOpen] = useState(false)
    const darkHeader = headerStyle === 'dark' && !menuIsOpen
    const [telIsOpen, setTelIsOpen] = useState(false)

    if (localStorage.getItem('PopupState') === null) {
        localStorage.setItem('PopupState', 'true')
    }

    if (document.body.clientWidth > 600) {
        document.addEventListener('mousemove', (e) => {
            if (localStorage.getItem('PopupState') === 'true') {
                if (e.pageY <= 5) {
                    setCatcherModalIsOpen(true)
                    localStorage.setItem('PopupState', 'false');
                };
            };
        });
    }

    useEffect(() => {
        document.body.className = menuIsOpen ? 'overflow' : ''
    }, [menuIsOpen])

    useEffect(() => {
        window.addEventListener('click', onTypesClickOutside)
        return () => window.removeEventListener('click', onTypesClickOutside)
    }, [dropdownIsOpen])

    const onTypesClickOutside = (e) => {
        if (dropdownIsOpen && dropdownRef.current === e.target) {
            setDropdownIsOpen(false)
        }
    }

    useEffect(() => {
        window.addEventListener('click', onContactsClickOutside)
        return () => window.removeEventListener('click', onContactsClickOutside)
    }, [contactIsOpen])

    const onContactsClickOutside = (e) => {
        if (contactIsOpen && contactRef.current === e.target) {
            setContactIsOpen(false)
        }
    }

    return (
        <>
            <StyledHeader isVisible={isVisible} dark={darkHeader}>
                <StyledContainer>
                    <StyledHeader.Top dark={darkHeader}>
                        <StyledHeader.Inner>
                            <StyledHeader.Types>
                                <StyledHeader.Type
                                    active
                                    white={darkHeader}
                                    isOpen={dropdownIsOpen}
                                    onClick={
                                        windowWidth <= 550
                                            ? () => setDropdownIsOpen(true)
                                            : undefined
                                    }
                                >
                                    Частным клиентам
                                </StyledHeader.Type>
                                <StyledHeader.Type link white={darkHeader}>
                                    <a href="https://business.atlcredit.by">Кредиты для бизнеса</a>
                                </StyledHeader.Type>
                            </StyledHeader.Types>
                            <StyledMobileNav.Dropdown isVisible={dropdownIsOpen} ref={dropdownRef}>
                                <StyledMobileNav.DropdownInner>
                                    <StyledMobileNav.DropdownItem
                                        padding
                                        onClick={() => setDropdownIsOpen(false)}
                                    >
                                        Частным клиентам
                                    </StyledMobileNav.DropdownItem>
                                    <StyledMobileNav.DropdownItem>
                                        <a href="https://business.atlcredit.by">
                                            Кредиты для бизнеса
                                        </a>
                                    </StyledMobileNav.DropdownItem>
                                </StyledMobileNav.DropdownInner>
                            </StyledMobileNav.Dropdown>
                            <StyledHeader.Actions>
                                <CityChange isVisible={isVisible} dark={darkHeader} />
                                <StyledHeader.Action onClick={() => setModalIsOpen(true)}>
                                    <SvgIcon name="call" width="16" height="16" />
                                    Связаться
                                </StyledHeader.Action>
                            </StyledHeader.Actions>
                            <StyledHeader.Messenger>
                                <a href="viber://pa?chatURI=cfc">
                                    <SvgIcon name="viber" width="16" height="16" />
                                </a>
                            </StyledHeader.Messenger>
                            <StyledHeader.Phone >
                                <StyledHeader.PhoneItem href='tel:+375298883222'>+375 (29) 888-3-222 (МТС)</StyledHeader.PhoneItem>
                                <span>, </span>
                                <StyledHeader.PhoneItem href='tel:+375291226800'>+375 (29) 122-6-800 (А1)</StyledHeader.PhoneItem>
                            </StyledHeader.Phone>
                        </StyledHeader.Inner>
                    </StyledHeader.Top>

                    <StyledHeader.Inner>
                        <StyledToggle onClick={() => setMenuIsOpen(!menuIsOpen)}>
                            <StyledToggle.Inner isOpen={menuIsOpen} />
                        </StyledToggle>
                        <StyledHeader.Logo white={darkHeader}>
                            <Link
                                as={city.is_default ? undefined : `/${city.slug}`}
                                href={city.is_default ? '/' : '/[slug]'}
                            >
                                <a>
                                    <SvgIcon name={darkHeader ? 'logo-dark' : 'logo'} width={260} height={45} />
                                </a>
                            </Link>
                        </StyledHeader.Logo>
                        <Nav dark={darkHeader} setAuthModalIsOpen={setAuthModalIsOpen} />
                        <CityChange mobile isVisible={isVisible} dark={darkHeader} />
                        <StyledHeader.Lk
                            onClick={() =>
                                user ? Router.push('/appeal') : setAuthModalIsOpen(true)
                            }
                        >
                            <SvgIcon name="user-filled" width={16} height={16} />
                        </StyledHeader.Lk>
                    </StyledHeader.Inner>
                </StyledContainer>
                <CallbackModal
                    isOpen={modalIsOpen}
                    onClose={setModalIsOpen}
                    title="Закажите обратный звонок"
                    text="Укажите свои данные, чтобы мы смогли с вам связаться и ответить на все вопросы."
                    onSubmitMethod={(values) =>
                        api.customRecord({
                            ...values,
                            credit_type: `Заявка на обратный звонок`,
                        })
                    }
                />
                <CatcherModal
                    isOpen={catcherModalIsOpen}
                    onClose={setCatcherModalIsOpen}
                    title="Подождите минутку! Вас ожидают 2 подарка"
                    text="Бесплатная проверка кредитной истории + консультация по условиям лучшего кредита! 
                    Заполни контактные данные, а мы перезвоним в течение 10 мин.и поможем с любыми финансовыми вопросами!"
                    onSubmitMethod={(values) =>
                        api.customRecord({
                            ...values,
                            credit_type: `Заявка на обратный звонок`,
                        })
                    }
                />
            </StyledHeader>

            <StyledHeader.ContactWrapper
                isVisible={contactIsOpen}
                ref={contactRef}
                top={testIsVisible && mayBeVisible}
            >
                <StyledHeader.ContactIcon
                    onClick={() => {
                        setModalIsOpen(true)
                        setContactIsOpen(false)
                    }}
                >
                    <SvgIcon name="contact" width="24" height="24" />
                </StyledHeader.ContactIcon>
            </StyledHeader.ContactWrapper>

            <AuthModal isOpen={authModalIsOpen} onClose={setAuthModalIsOpen} />

            <MobileNav
                top={isVisible}
                isOpen={menuIsOpen}
                toggleMenuVisibility={() => setMenuIsOpen(!menuIsOpen)}
                onBtnClick={() => {
                    setMenuIsOpen(false)
                    setModalIsOpen(true)
                }}
                setAuthModalIsOpen={setAuthModalIsOpen}
            />
        </>
    )
}
